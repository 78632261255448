import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Consulta from './components/Consulta';
import Resultado from './components/Resultado';
// import Show from './components/Show';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import Restituicao from './components/Restituicao';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Consulta />} />
        <Route path="/calculadora" element={<Consulta />} />
        <Route path="/resultado" element={<Resultado />} />
        <Route path="/restituicao" element={<Restituicao />} />
        {/* <Route path="/result/:id" element={<Show />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
