import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
// import { IMaskInput } from 'react-imask';
import { CurrencyInput } from 'react-currency-mask';
// import CurrencyInput from 'react-currency-input-field';
import image from '../cartilha.png';
import axios from 'axios';

const Consulta = () => {
  const [formData, setFormData] = useState({
    recebeu: '',
    setor: '',
    cargo: '',
    cargahoraria: '40',
    salario: '',
    vantagem: '',
    // whatsapp: '',
  });

  const [msg, setMsg] = useState('');

  const navigate = useNavigate();

  const baseUrl = 'https://api-test-dj.vercel.app';
  // const baseUrl = 'http://192.168.1.50:5000';

  const handleSubmitForm = async (event) => {
    let sal = formData.salario.replace(',', '').replace('.', '');
    formData.salario = sal.substr(0, sal.length - 2) + '.' + sal.substr(-2, 2);
    console.log(formData);
    event.preventDefault();
    const response = await axios.post(`${baseUrl}/consulta`, formData);

    navigate('/resultado', { state: response.data });
  };

  const handleOnChangeForm = (event, key) => {
    setFormData({ ...formData, [key]: event.target.value });
  };

  const validaForm = () => {
    setMsg('Campo obrigatório.');
  };

  return (
    <div className="App container p-3">
      <div className="row m-3">
        <h1 className="text-center text-white">Calculadora do Complemento</h1>
      </div>

      <div className="form px-2 rounded">
        <form onSubmit={handleSubmitForm}>
          <div className="row">
            <div>
              <img
                className="img-fluid rounded mx-auto my-2"
                src={image}
                alt=""
              />
            </div>
          </div>

          <div
            className="row gx-2 mt-3"
            onChange={(event) => handleOnChangeForm(event, 'recebeu')}
          >
            <h4>
              Você já recebeu algum complemento do piso da enfermagem?{' '}
              <span className="text-danger">*</span>
            </h4>
            <div className="col">
              <input
                type="radio"
                className="btn-check"
                name="recebeu"
                id="sim"
                value="sim"
                required
              />
              <label className="d-grid  btn-secondary" htmlFor="sim">
                Sim
              </label>
            </div>
            <div className="col">
              <input
                type="radio"
                className="btn-check"
                name="recebeu"
                id="nao"
                value="nao"
              />
              <label className="d-grid  btn-secondary" htmlFor="nao">
                Não
              </label>
            </div>
          </div>
          <div className="msg-alert">
            {formData.recebeu === '' && (
              <span className="text-danger">{msg}</span>
            )}
          </div>

          <div
            className="row gx-2 mt-5"
            onChange={(event) => handleOnChangeForm(event, 'setor')}
          >
            <h4>
              Trabalha no setor público ou privado?{' '}
              <span className="text-danger">*</span>
            </h4>
            <div className="col">
              <input
                type="radio"
                className="btn-check"
                name="setor"
                id="publico"
                value="publico"
                required
              />
              <label className="d-grid  btn-secondary" htmlFor="publico">
                Público
              </label>
            </div>
            <div className="col">
              <input
                type="radio"
                className="btn-check"
                name="setor"
                id="privado"
                value="privado"
              />
              <label className="d-grid  btn-secondary" htmlFor="privado">
                Privado
              </label>
            </div>
          </div>
          <div className="msg-alert">
            {formData.setor === '' && (
              <span className="text-danger">{msg}</span>
            )}
          </div>

          <div
            className="row gx-2 gy-2 mt-5"
            onChange={(event) => handleOnChangeForm(event, 'cargo')}
          >
            <h4>
              Qual o seu cargo? <span className="text-danger">*</span>
            </h4>
            <div className="col-xs-12 col-md-3">
              <input
                type="radio"
                className="btn-check"
                id="enfermeiro"
                name="cargo"
                value="enfermeiro"
                required
              />
              <label className="d-grid btn-secondary" htmlFor="enfermeiro">
                Enfermeiro
              </label>
            </div>
            <div className="col-xs-12 col-md-3">
              <input
                type="radio"
                className="btn-check"
                id="tecnico"
                name="cargo"
                value="tecnico"
              />
              <label className="d-grid btn-secondary" htmlFor="tecnico">
                Técnico
              </label>
            </div>
            <div className="col-xs-12 col-md-3">
              <input
                type="radio"
                className="btn-check"
                id="auxiliar"
                name="cargo"
                value="auxiliar"
              />
              <label className="d-grid btn-secondary" htmlFor="auxiliar">
                Auxiliar
              </label>
            </div>
            <div className="col-xs-12 col-md-3">
              <input
                type="radio"
                className="btn-check"
                id="parteiro"
                name="cargo"
                value="parteiro"
              />
              <label className="d-grid btn-secondary" htmlFor="parteiro">
                Parteiro
              </label>
            </div>
          </div>
          <div className="msg-alert">
            {formData.cargo === '' && (
              <span className="text-danger">{msg}</span>
            )}
          </div>

          <div className="row mt-5">
            <h4>
              Qual é a sua jornada semanal (carga horária)?{' '}
              <span className="text-danger">*</span>
            </h4>
            <div className="col">
              <select
                className="form-select"
                id="cargahoraria"
                value={formData.cargahoraria}
                onChange={(event) => handleOnChangeForm(event, 'cargahoraria')}
              >
                <option value="20">20 horas</option>
                <option value="22">22 horas</option>
                <option value="24">24 horas</option>
                <option value="26">26 horas</option>
                <option value="28">28 horas</option>
                <option value="30">30 horas</option>
                <option value="32">32 horas</option>
                <option value="34">34 horas</option>
                <option value="36">36 horas</option>
                <option value="38">38 horas</option>
                <option value="40">40 horas</option>
                <option value="42">42 horas</option>
                <option value="44">44 horas</option>
              </select>
            </div>
          </div>

          <div className="row mt-5">
            <h4>
              Informe o salário base (mensal) + vantagens fixas?{' '}
              <span className="text-danger">*</span>
            </h4>
            <p></p>
            <div className="col input-group">
              <span className="input-group-text">R$</span>

              {/* <IMaskInput
                mask={Number}
                // scale={2}
                // radix=","
                // padFractionalZeros={true}
                // normalizeZeros={true}
                min={1}
                max={10000}
                // thousandsSeparator="."
                type="text"
                className="form-control"
                id="salario"
                name="salario"
                value={formData.salario}
                onChange={(event) => handleOnChangeForm(event, 'salario')}
                required
              /> */}
              <CurrencyInput
                className="form-control"
                hideSymbol="true"
                id="salario"
                name="salario"
                value={formData.salario}
                onChangeValue={(event, originalValue, maskedValue) => {
                  handleOnChangeForm(event, 'salario');
                }}
                // onChange={(event) => handleOnChangeForm(event, 'salario')}
                required
              />
              {/* <CurrencyInput
                id="salario"
                name="salario"
                value={formData.salario}
                placeholder="Please enter a number"
                defaultValue={1000}
                decimalsLimit={2}
                className="form-control"
                onValueChange={(value, name) =>
                  handleOnChangeForm(value, 'salario')
                }
              /> */}
            </div>
            <div className="">{}</div>
          </div>
          <div className="msg-alert">
            {formData.salario === '' && (
              <span className="text-danger">{msg}</span>
            )}
          </div>

          {/* <div className="row mt-5">
            <h4>Qual é o valor das vantagens fixas?</h4>
            <div className="col input-group">
              <span className="input-group-text">R$</span>
              <IMaskInput
                mask={Number}
                // scale={2}
                // radix=","
                // padFractionalZeros={false}
                // normalizeZeros={true}
                min={1}
                max={10000}
                // thousandsSeparator="."
                type="text"
                className="form-control"
                id="vantagem"
                name="vantagem"
                value={formData.vantagem}
                onChange={(event) => handleOnChangeForm(event, 'vantagem')}
              />
            </div>
          </div> */}

          {/* <div className="row mt-5">
            <h4>
              Seu WhatsApp? <span className="text-danger">*</span>
            </h4>
            <div className="col input-group">
              <span className="input-group-text">
                <i className="fa fa-whatsapp"></i>
              </span>
              <IMaskInput
                mask="(00) 90000-0000"
                type="text"
                id="whatsapp"
                name="whatsapp"
                className="form-control"
                required
                value={formData.whatsapp}
                onChange={(event) => handleOnChangeForm(event, 'whatsapp')}
              />
            </div>
          </div> */}

          <div className="row mt-2">
            <div className="col d-grid my-4">
              <button
                type="submit"
                className="button-68 btn-block"
                value="calcular"
                onClick={validaForm}
              >
                CALCULAR COMPLEMENTO
              </button>
            </div>
          </div>
        </form>
      </div>

      {/* <form className="" onSubmit={handleSubmitForm}>
        <h1>Calculadora do Complemento - Piso da Enfermagem</h1>

        <div onChange={(event) => handleOnChangeForm(event, 'recebeu')}>
          <input type="radio" id="sim" name="recebeu" value="sim" required />
          <label htmlFor="sim">Sim</label>
          <br />
          <input type="radio" id="nao" name="recebeu" value="nao" />
          <label htmlFor="nao">Não</label>
        </div>
        <br />
        <hr />

        <div onChange={(event) => handleOnChangeForm(event, 'setor')}>
          <input
            type="radio"
            id="publico"
            name="setor"
            value="publico"
            required
          />
          <label htmlFor="publico">Público</label>
          <br />
          <input type="radio" id="privado" name="setor" value="privado" />
          <label htmlFor="privado">Privado</label>
        </div>
        <br />
        <hr />

        <div onChange={(event) => handleOnChangeForm(event, 'cargo')}>
          <input
            type="radio"
            id="enfermeiro"
            name="cargo"
            value="enfermeiro"
            required
          />
          <label htmlFor="enfermeiro">Enfermeiro</label>
          <br />
          <input type="radio" id="tecnico" name="cargo" value="tecnico" />
          <label htmlFor="tecnico">Técnico</label>
          <br />
          <input type="radio" id="auxiliar" name="cargo" value="auxiliar" />
          <label htmlFor="auxiliar">Auxiliar</label>
          <br />
          <input type="radio" id="parteiro" name="cargo" value="parteiro" />
          <label htmlFor="parteiro">Parteiro</label>
        </div>
        <br />
        <hr />

        <label htmlFor="cargahoraria">Jornada semanal (carga horária)</label>
        <select
          id="cargahoraria"
          value={formData.cargahoraria}
          onChange={(event) => handleOnChangeForm(event, 'cargahoraria')}
        >
          <option value="12">12 Horas</option>
          <option value="14">14 Horas</option>
          <option value="16">16 Horas</option>
          <option value="18">18 Horas</option>
          <option value="20">20 Horas</option>
          <option value="22">22 Horas</option>
          <option value="24">24 Horas</option>
          <option value="26">26 Horas</option>
          <option value="28">28 Horas</option>
          <option value="30">30 Horas</option>
          <option value="32">32 Horas</option>
          <option value="34">34 Horas</option>
          <option value="36">36 Horas</option>
          <option value="38">38 Horas</option>
          <option value="40">40 Horas</option>
          <option value="42">42 Horas</option>
          <option value="44">44 Horas</option>
        </select>
        <br />
        <hr />

        <label htmlFor="salario">Salário base</label>
        <input
          type="text"
          id="salario"
          name="salario"
          required
          value={formData.salario}
          onChange={(event) => handleOnChangeForm(event, 'salario')}
        />
        <br />
        <hr />
        <label htmlFor="vantagem">Vantagem fixa</label>
        <IMaskInput
          mask={Number}
          scale={2}
          padFractionalZeros={true}
          normalizeZeros={true}
          min={-10000}
          max={10000}
          radix=","
          thousandsSeparator="."
          type="text"
          id="vantagem"
          name="vantagem"
          value={formData.vantagem}
          onChange={(event) => handleOnChangeForm(event, 'vantagem')}
        />
        <br />
        <hr />
        <label htmlFor="whatsapp">WhatsApp</label>
        <IMaskInput
          mask="(00) 90000-0000"
          type="text"
          id="whatsapp"
          name="whatsapp"
          required
          value={formData.whatsapp}
          onChange={(event) => handleOnChangeForm(event, 'whatsapp')}
        />
        <br />
        <hr />
        <button type="submit" value="calcular">
          Calcular complemento
        </button>
      </form> */}
      <div className="row mt-4">
        <div>
          <p className="f1 text-center">
            Politicas de Privacidade | Termos de Uso
          </p>
        </div>
        <div>
          <p className="f2 text-center">Site Seguro</p>
        </div>
      </div>
    </div>
  );
};

export default Consulta;
