import React from 'react';
import { Link } from 'react-router-dom';
import image from '../irpf242401.png';

const Restituicao = () => {
  return (
    <div className="App container p-3">
      <div className="row m-3">
        <h1 className="text-center text-white h1r">
          Imposto de Renda 2024 Enfermagem
        </h1>
      </div>
      <div className="form px-2 rounded">
        <div className="row text-center">
          <div>
            <img
              className="img-fluid rounded mx-auto my-2"
              src={image}
              alt=""
            />
          </div>
        </div>
        <div className="row">
          {/* <h4 className="lbl-h4">INFORMAÇÕES DIRETO NO SEU WHATSAPP</h4> */}
          <h4 className="lbl-h4">
            CONFIE SEU IMPOSTO DE RENDA EM QUEM ENTENDE!
          </h4>
          <p className="text-center">-Declaração 100% online</p>
          <p className="text-center">-Declaração em 48h</p>
          <p className="text-center">-Feito por especialistas</p>
          <p className="text-center">-Suporte o ano inteiro</p>
          <div className="text-center mb-4">
            <Link
              to={
                'https://api.whatsapp.com/send?phone=5531998132384&text=Quero%20declarar%20meu%20imposto%20de%20renda%202024.'
              }
            >
              <button className="button-37 w-75">
                <i className="fa fa-whatsapp fa-lg"></i> Vamos lá
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div>
          <p className="f1 text-center">
            Politicas de Privacidade | Termos de Uso
          </p>
        </div>
        <div>
          <p className="f2 text-center">Site Seguro</p>
        </div>
      </div>
    </div>
  );
};

export default Restituicao;
