import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import image from '../complemento.png';
import { useState, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const Resultado = () => {
  const localtion = useLocation();
  const compl = localtion.state;
  // console.log(compl.complemento);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  return (
    <div>
      {loading ? (
        <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
          <ClipLoader color={'#fff'} loading={loading} size={100} />
        </div>
      ) : (
        <div className="App container p-3">
          <div className="row m-3">
            <h1 className="text-center text-white h1r">Resultado</h1>
          </div>
          <div className="form px-2 rounded">
            <div className="row">
              <div>
                <img
                  className="img-fluid rounded mx-auto my-2"
                  src={image}
                  alt=""
                />
              </div>
            </div>

            <div className="row">
              <p>
                <i className="fa fa-stethoscope"></i> Cargo:{' '}
                {compl.cargo === 'enfermeiro' && 'Enfermeiro'}
                {compl.cargo === 'tecnico' && 'Téc. Enf.'}
                {compl.cargo === 'auxiliar' && 'Aux. Enf.'}
                {compl.cargo === 'parteiro' && 'Parteiro'} / Piso:{' '}
                {compl.cargo === 'enfermeiro' && 'R$4750,00'}
                {compl.cargo === 'tecnico' && 'R$3325,00'}
                {compl.cargo === 'auxiliar' && 'R$2375,00'}
                {compl.cargo === 'parteiro' && 'R$2375,00'}
              </p>
              <p>
                <i className="fa fa-clock-o"></i> Jornada semanal:{' '}
                {compl.cargahoraria + ' horas'}
              </p>
              <p>
                <i className="fa fa-calculator"></i> Salário proporcional: R$
                {compl.sproporcional.toFixed(2).replace('.', ',')}
              </p>
              <p className="comp">
                <i className="fa fa-money"></i> Complemento (mensal):{' '}
                <span className="lbl-cmpl">
                  <i>
                    <b>
                      {'R$ ' + compl.complemento.toFixed(2).replace('.', ',')}
                    </b>
                  </i>
                </span>
              </p>
            </div>
            <hr />

            <div className="row">
              {/* <h4 className="lbl-h4">INFORMAÇÕES DIRETO NO SEU WHATSAPP</h4> */}
              <h4 className="lbl-h4">
                PARTICIPE DO GRUPO EXCLUSIVO DO WHATSAPP
              </h4>
              <p className="text-center">
                Receba, em primeira mão, informações sobre o piso da enfermagem.
              </p>
              <div className="text-center mb-4">
                <Link to={'https://chat.whatsapp.com/KlQzZQzOCy67zm6PQbAS5I'}>
                  <button className="button-37 w-75">
                    <i className="fa fa-whatsapp fa-lg"></i> Entrar agora
                  </button>
                </Link>
              </div>
            </div>

            {/* <div className="row">
              <h4>INFORMAÇÕES DIRETO NO SEU WHATSAPP</h4>
              <p className="text-center">
                Receba, em primeira mão, informações sobre o piso da enfermagem.
              </p>
              <div className=" text-center mb-4">
                <Link
                  to={
                    'https://api.whatsapp.com/send?phone=5531995125368&text=Quero%20receber%20informa%C3%A7%C3%B5es%20sobre%20o%20Piso%20da%20Enfermagem.%20Meu%20c%C3%B3digo%20%C3%A9%20' +
                    compl.code +
                    '.'
                  }
                >
                  <button className="button-37 w-75">
                    <i className="fa fa-whatsapp fa-lg"></i> Quero Receber
                  </button>
                </Link>
              </div>
            </div> */}
          </div>
          <div className="row mt-4">
            <div>
              <p className="f1 text-center">
                Politicas de Privacidade | Termos de Uso
              </p>
            </div>
            <div>
              <p className="f2 text-center">Site Seguro</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Resultado;
